$milk: #0d0d0d;
$maxw: 920px;
$teal: #2a27ff;
$bright-teal: #2a27ff;

$accent-color: #2a27ff;

h1 {
  font-size: 50px;
  line-height: 70px;
  font-weight: 400;
  letter-spacing: 1.2px;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
}

h3 {
  font-size: 2rem;
  font-weight: 800;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px 0 30px 0;
}

h4 {
  padding: 10px 0 30px 0;
}

a > h2:hover {
  color: rgb(235, 97, 97);
  transform: scale(1.05);
}

.proj-line1 {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  // background: pink;
}

.proj-date {
  font-family: "parabolica";
  font-size: 0.8rem;
  line-height: 100%;
  height: 50%;
  // align-items: center;
  // align-self: flex-end;
  padding-left: 8px;
  margin-top: 10px;
}

.proj-desc {
  font-family: "parabolica";
  font-size: 0.9rem;
}

.headline {
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px 0 30px 0;
}

a {
  color: #f0f0f0ff;
  text-decoration: none;
  // font-weight: 500;
  // font-size: 0.9rem;
  display: inline-block;
}

.link {
  color: #3f3f3f;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.3rem;

  &:hover {
    border-bottom: 3px solid $milk;
    transform: scale(1.1);
    font-weight: 600;
  }
}

p {
  max-width: 50ch !important;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 15px 0;
}

.highlight {
  background: linear-gradient(to bottom, transparent 60%, #227167 50%);

  &:hover {
    cursor: pointer;

    background: #227167;
  }
}

// nav bar

// circle

.logo {
  position: static;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background-color: #2a27ff;
  color: #2a27ff;
}

nav {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
}

.nav-w {
  @media screen and (max-width: 800px) {
    display: none;
  }

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  width: 94%;
  height: 100%;
  padding: 30px 20px 30px 30px;
  max-width: 1320px;
  // background: white;
}

#nav-links {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  color: #f0f0f0;
}

a.active {
  color: #2a27ff;
  font-weight: 600;
}

.nav-m {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 94%;
  height: 100%;
  padding: 30px 20px 30px 30px;
  max-width: 1320px;
  background: rgb(13, 13, 13);
}

.menu-icon {
  cursor: pointer;
}

.nav-stack {
  position: absolute;
  top: 80px;
  right: 0px;
  padding: 10px 10px 30px 10px;
  display: flex;
  flex-flow: column;

  align-items: center;
  justify-content: center;

  text-align: center;

  gap: 10px;
  width: 100%;
  min-height: 100vh;
  background: rgb(13, 13, 13);

  & > * {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.6rem;
  }

  & > *:hover {
    transform: scale(1.1);
  }
}

em {
  color: rgb(135, 255, 217);
  text-shadow: #7dffee;
  font-weight: 700;
}

// extras
.divider {
  width: 96%;
  height: 1px;
  background: #909090;
  margin: 70px 0;
}

// footer stuff
footer {
  width: 100%;
  color: #f0f0f0;
  padding: 50px 50px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
}

.intro {
  width: 100%;
  max-width: $maxw;
  display: flex-column;
  margin: 10px;
  gap: 40px;
}

.about-h {
  width: 100%;
  font-size: 3rem;
  font-weight: 900;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}

.about-body {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.about-content {
  width: 50%;
  min-width: 300px;
  @media screen and (max-width: 901px) {
    width: 100%;
    margin: 0 20px;
  }
}

.me-img {
  width: 50%;
  max-width: 205px;
  height: auto;
  border-radius: 50%;
}

.me2-img {
  width: 70%;
  max-width: 235px;
  height: 100%;
  border-radius: 20px;
  flex-basis: 1;
}

.row {
  display: flex;
  flex-flow: row wrap;
  gap: 15px;
  margin: 20px 0;
}

.icons {
  padding: 0 0 80px 0;
  display: flex;
  flex-flow: row;
  gap: 20px;
  align-items: center;

  color: rgb(135, 255, 217);

  & > *:hover {
    transform: scale(1.1);
    cursor: pointer;
    color: rgb(53, 209, 160);
  }
}

.footer-icons {
  display: flex;
  flex-flow: row;
  gap: 20px;
  align-items: center;

  color: #c5c5c5;

  & > *:hover {
    transform: scale(1.1);
    cursor: pointer;
    color: $accent-color;
  }
}

.down {
  vertical-align: bottom;
  &:hover {
    transform: scale(1.1);
  }
}

.page {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.half {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  width: 100%;
  gap: 15px;
  padding: 30px 0;

  // background: $milk;
}

.milk {
  background: $milk;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 1600px;
  min-height: 100vh;
  height: 100%;

  color: #fff;
  font-size: 1.5rem;
  padding: 40px 20px;

  // margin-right: auto;
  // margin-left: auto;

  transition: all 0.3s ease-out;
}
