.projects {
  display: flex;
  flex-flow: column nowrap;
  width: 92%;
  gap: 16px;
}

.projects-pics {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(350px, max-content));
  gap: 40px;
  justify-content: center;
  position: relative;
  margin-top: 80px;
  padding: 10px 20px;
  max-width: 1270px;
}

.project {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;

  width: 100%;

  position: relative;
  z-index: 1;

  & > a {
    color: #a70000;
    font-weight: 600;
    &:hover {
      transform: none;
      font-weight: 700;
      transform: scale(1);

      cursor: pointer;
      border-bottom: none;
    }
  }
}

.p-title {
  font-size: 2rem;
  font-weight: 900;
  max-width: 90%;
  color: rgb(135, 255, 217);
}

.gh-icon {
  position: relative;
  right: 1px;
  top: 8px;
  color: rgb(135, 255, 217);

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    color: rgb(132, 243, 207);
  }
}

.stack {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 50px;
}

.row {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.proj {
  width: 100%;
  max-width: 550px;
  height: auto;
  padding: 5%;
  color: #fff;
}

.proj-img {
  width: 100%;
  height: 100%;
  max-height: 350px;
  max-width: 550px;

  object-fit: cover;
}

.p-link {
  color: rgb(135, 255, 217);
}

// my changes

.p-title {
  font-size: 2rem;
  font-weight: 900;
  max-width: 90%;
}

.p-des {
  font-size: 0.9rem;
  color: #fff;
}

.techs {
  padding-top: 20px;
  display: flex;
  flex-flow: row wrap;
  gap: 5px;
  width: 100%;
}
.tech {
  background: rgb(135, 255, 217);
  border-radius: 40px;
  padding: 10px;
  color: #000000;
  font-size: 1rem;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    background: #747474;
  }
}
