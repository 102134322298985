.info-header {
  font-family: "parabolica", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 600;
  margin-bottom: 30px;
  width: 40%;
}

.info-container {
  font-family: "parabolica", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 0.9rem;
  width: 60%;
}

.info-page {
  width: 92%;
  display: flex;
  // align-items: center;
  justify-content: center;
  text-align: left;
  max-width: 1600px;
  padding: 0 0 100px 0;
}

@media (max-width: 850px) {
  .info-page {
    flex-flow: column nowrap;
    width: 100%;
  }
  .info-container {
    width: 100%;
  }
}
