$milk: #2f2f2f;

#resume {
  width: 100%;
  height: 800px;
}

.watch-list {
  margin: 200px 0;
}

#boba-1 {
  top: 50px;
  left: 25px;
  animation-duration: 7s;
}
#boba-2 {
  top: 50px;
  right: 60%;
  animation-duration: 4s;
}

#boba-3 {
  top: 65px;
  animation-duration: 8s;
  animation-delay: 3s;
}

// about
#boba-4 {
  top: 100px;
  right: 30%;
  animation-duration: 6s;
}

// resume
#boba-5 {
  top: 60px;
  right: 20px;
  animation-duration: 3s;
}

.boba-float {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 1 !important;
  background-color: $milk;

  animation-name: floating;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  &:hover {
    cursor: pointer;
  }
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.list-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;

  gap: 15px;
  width: 100%;
}

.show {
  display: flex;
  flex-flow: column nowrap;
  gap: 15px;
  justify-content: space-between;
  align-items: center;

  font-size: 1.1rem;
}

.show-img {
  max-width: 205px;
  height: auto;
  width: 100%;
}

.animation {
  animation: fadeInAnimation ease 3.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 1;
    transform: translateY(125px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-down {
  animation: fadeDownAnimation ease 3.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeDownAnimation {
  0% {
    opacity: 1;
    transform: translateY(-125);
  }
  100% {
    opacity: 1;
    transform: translateY(125);
  }
}
