* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "parabolica", -apple-system, BlinkMacSystemFont, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0d0d0d;
}

.sans {
  /* font-family: "lemonde-journal", serif; */
  /* font-family: "freight-big-pro", serif; */
  font-family: "ivypresto-display", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  cursor: pointer;

  background: none;
  border: none;
  color: inherit;
}

header {
  position: relative;
}

main {
  z-index: 5;
}

footer {
  position: relative;
}
