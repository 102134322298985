/* waves */

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -12px;
  min-height: 100px;
  max-height: 150px;
  z-index: -1;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 8s;
}

.parallax > use:nth-child(2) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(2) {
  animation-delay: -6s;
  animation-duration: 17s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
