$accent-color: #2a27ff;

.hook {
  width: 92%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  max-width: 1600px;
  padding: 0 0 100px 0;
}

.name {
  font-size: 50px;
  line-height: 70px;
  font-weight: 400;
  letter-spacing: 1.2px;
  width: 75%;
}

.underline {
  border-bottom: 4px solid $accent-color;
  padding-bottom: 5px;
}

.etc {
  width: 100%;
  flex-flow: column nowrap;
  padding: 30px 0;
  font-size: 0.9rem;
  line-height: 1.2rem;
  letter-spacing: inherit;
  font-family: "parabolica", -apple-system, BlinkMacSystemFont, sans-serif;

  a {
    color: rgb(255, 125, 125);
  }
  a:hover {
    transform: none;
    color: rgb(255, 32, 32);
  }
}

.cornell {
  width: 100%;
}

.connect {
  width: 126px;
  padding: 4px 8px 6px;
  margin-top: 128px;
  font-size: 0.9rem;
  font-family: "parabolica", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;

  // color: white !important;
  background-color: white;

  &:hover {
    transform: none;
    color: rgb(255, 32, 32) !important;

    cursor: pointer;
    // border-bottom: none;
  }
}

.fade-in {
  animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.memoji-container {
  width: 25%;
  height: 100%;
  display: flex;
}

.memoji {
  max-width: 140px;

  margin-left: auto;
  margin-right: auto;
  margin-top: -125px;
  object-fit: cover;
  border-radius: 50%;
}

.slide-up {
  animation: slideUp 2s;
}

@keyframes slideUp {
  0%,
  50% {
    transform: translateY(70%);
    opacity: 0.8;
  }
  60%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

// mobile

@media (max-width: 850px) {
  .hook {
    flex-flow: column nowrap;
  }
  .name,
  .memoji-container {
    width: 100%;
  }
  .memoji {
    max-width: 250px;

    margin-top: 36px;
  }
}

// projects

.project-header {
  width: 92%;
  font-size: 1.15rem;
  letter-spacing: 1.3px;
  padding-bottom: 48px;
}
